<script>
const sidebarMenu = [
  {
    path: '/monitoring', icon: 'fa fa-tv', title: 'Suivi & Contrôle',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/admin', icon: 'fas fa-cogs', title: 'Console Admin.',
  },
];

export default sidebarMenu;
</script>
